<template>
  <v-app class="bg" :style="backgroundImage">
    <v-dialog-confirm :show="isConfirm" :email="username" :resend="resend" :back="closeDialog"/>
    <v-main>
      <img :src="hospitalLogo" width="180" class="logo" />
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <!-- Form -->
            <div v-if="!progressing">
              <div class="d-flex justify-center px-4" t-data="error-alert">
                <v-alert
                  v-model="fail"
                  dense
                  outlined
                  class="danger800Color--text custom-alert"
                ><span v-html="msgError">{{msgError}}</span>
                </v-alert>
              </div>

              <v-card flat>
                <v-card-text>
                  <v-form lazy-validation>
                    <label for="username" t-data="info-input">
                      Email
                      <v-text-field
                        id="username"
                        v-model="username"
                        :error-messages="nameErrors"
                        type="text"
                        placeholder="john@medcury.health"
                        change="$v.username.$touch()"
                        @blur="$v.username.$touch()"
                        @keyup.enter.native="submit"
                        outlined
                      />
                    </label>

                    <label for="password" t-data="info-input">
                      Password
                      <v-text-field
                        id="password"
                        v-model="password"
                        :error-messages="pswErrors"
                        placeholder="******"
                        type="password"
                        change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        @keyup.enter.native="submit"
                        outlined
                      />
                    </label>
                  </v-form>
                </v-card-text>
                <div>
                  <v-btn t-data="forget-btn" @click="clickForgetPassword" text color="white">Forgot password</v-btn>
                </div>
                <v-card-actions>
                  <!-- <a href="#">Forget password?</a> -->
                  <v-spacer />
                  <v-btn @click.stop="submit" large class="btn-login" t-data="login-btn">Login</v-btn>
                </v-card-actions>
              </v-card>
            </div>

            <!-- Spinner -->
            <div v-if="progressing" class="text-center">
              <v-progress-circular :size="50" color="textColor" indeterminate></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {imageHospitalBg, imageHospitalLogo} from '@/constants/constants'
import Confirm from '@/components/AlertForgetPass'

export default {
  mixins: [validationMixin],

  validations: {
    username: {required},
    password: {required}
  },
  components: {
    'v-dialog-confirm': Confirm,
  },
  computed: {
    backgroundImage() {
      return {
        "background-image": `url(${imageHospitalBg})`
      }
    },
    hospitalLogo() {
      return imageHospitalLogo
    },
    nameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required && errors.push('You must specify email.')
      return errors
    },
    pswErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('You must specify password.')
      return errors
    }
  },

  methods: {
    async submit() {
      // Validate form.
      this.$v.$touch()
      if (this.$v.$invalid) return

      // Show waiter inprogress.
      this.progressing = true

      const payload = {
        user: this.username,
        pass: this.password
      }

      try {
        const success = await this.login(payload)
        if (success) {
          return this.$router.push({name: 'home'})
        }
      } catch (e) {
        if (
          e.response.data.hasOwnProperty('login_fail_attempt') &&
          e.response.data.login_fail_attempt >= 5
        ) {
          this.fail = false
          this.showDialog()
        } else {
          // Show error message
          this.fail = true
          this.msgError = e.response.data.detail
        }
      }
      this.progressing = false
    },
    clickForgetPassword(){
      this.$router.push({name: 'forget-passw'})
    },
    showDialog() {
      this.isConfirm = true;
    },
    resend(){
      this.isConfirm = false;
      this.submitForget()
    },
    async submitForget (){
      let status = await this.forget(this.username)
      if (status) {
        this.fail = false
        this.msgError = ''
        return
      }
      this.fail = true
      this.msgError = 'Email is not found'
    },
    closeDialog() {
      this.isConfirm = false;
    },
    ...mapActions('auth', ['login', 'forget'])
  },
  data() {
    return {
      msgError: '',
      fail: false,
      progressing: false,
      username: '',
      password: '',
      isConfirm: false
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: transparent;
}
.bg {
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: right top;
}
.logo {
  position: absolute;
  top: 103px;
  left: 55px;
}
form {
  & > label {
    color: white;
    font-size: 15px;
  }
}
.v-card__actions {
  a {
    color: white;
  }
  padding: 16px;
}
.v-input__slot {
  background: none;
  background-color: white !important;
}
.btn-login {
  width: 40%;
  background-color: var(--login-btn-color) !important;
  border-color: var(--login-btn-color) !important;
  color: white;
}
.custom-alert {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20.5px;
  background-color: var(--danger-200-color) !important;
  border-color:  var(--danger-400-color) !important;;
}
</style>

<style>
.v-input__slot {
  background: none;
  background-color: white !important;
}
</style>
